<template>
    <div class="wrap">
        <div class="content-box">
            <div class="logo"></div>
            <div class="logo-label">{{progress==1?'领取礼品':'企业认证'}}</div>
            <div class="form" v-if="progress==1">
                <div class="item">
                    <img class="icon" src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-6.png">
                    <input class="input" v-model="formData.phone" type="tel" placeholder="手机号码">
                </div>
                <div class="item item-code">
                    <img class="icon" src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-7.png">
                    <input class="input" v-model="formData.code" type="tel" placeholder="验证码">
                    <div class="button">
                        <div v-show="time<=0" @click="getCode">获取验证码</div>
                        <div v-show="time>0">{{time}}</div>
                    </div>
                </div>

                <div class="sub-button">
                    <van-button @click="checkPhone" color="#C70C0C" :loading="buttonLoad" loading-size="24px"
                                loading-text="加载中..." size="large" block>登录
                    </van-button>
                </div>
            </div>

            <div class="form" v-else>
                <div class="item">
                    <img class="icon" src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-11.png">
                    <input class="input" v-model="formData.name" placeholder="姓名">
                </div>
                <div class="item">
                    <img class="icon" src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-10.png">
                    <input class="input" v-model="formData.workNumber" type="tel" placeholder="工号">
                </div>

                <div class="sub-button">
                    <van-button @click="loginClick" color="#C70C0C" :loading="buttonLoadtwo" loading-size="24px"
                                loading-text="加载中..." size="large" block>查看礼品
                    </van-button>
                </div>
            </div>
<!--            <div class="logo-two"></div>-->

        </div>

      
      <div class="lin-box" @click="goCardLogin"><img style="width: 100%" src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/gift-22.png"></div>

<!--      <div class="lin-box">-->
<!--        <div class="left">-->
<!--          礼品卡兑换-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <div class="lin">兑换</div>-->
<!--        </div>-->
<!--      </div>-->








    </div>
</template>

<script>
    import {Button} from 'vant';
    export default {
        name: 'login',
        components: {
            [Button.name]: Button,
        },
        data() {
            return {
                progress:1,
                buttonLoad:false,
                buttonLoadtwo:false,
                formData:{
                    code:'',
                    phone:'',
                    volume_id:null,
                    name:'',
                    workNumber:''
                },
              user:{},
              token:'',



                int: undefined,
                time: 0,

                getcodeLoad:false,
            }
        },

        async created() {
            let nowTime=parseInt(new Date().getTime() / 1000)
            let setCodeTime=localStorage.getItem('setCodeTime')?parseInt(localStorage.getItem('setCodeTime')):0
            if(setCodeTime+60-nowTime>0){
                this.setTime(setCodeTime+60-nowTime)
            }
        },

        methods: {
          goCardLogin(){
            this.$router.push('/login/coupon')
          },
            async getCode(){
                if(!this.formData.phone){
                    this.$toast({message:'请输入手机号码'})
                    return false;
                }
                if(this.formData.phone.length!=11){
                    this.$toast({message:'请输入11位手机号码'})
                    return false;
                }
                if(this.getcodeLoad)return false;
                this.getcodeLoad=true
                let result=await this.$request({
                    method: 'get',
                    url:'/app/auth/login/phone',
                    params:{
                        phone: this.formData.phone,
                        // key:'CD123_'
                    }
                })
                this.getcodeLoad=false
                if(result.code==0){
                    this.$toast({message:'发送成功'})
                    this.setTime(60)
                }else {
                    this.$toast({message:'发送失败'})
                }
            },
            //检验手机号码
            async checkPhone(){
                if(!this.formData.phone){
                    this.$toast({message:'请输入手机号码'})
                    return false;
                }
                if(!this.formData.code){
                    this.$toast({message:'请输入验证码'})
                    return false;
                }

                this.buttonLoad=true
                let result=await this.$request({
                    method: 'post',
                    url:'/app/auth/login/oauth',
                    data:{
                        phone: this.formData.phone,
                        code:this.formData.code,
                    }
                })
                this.buttonLoad=false
                if(result.code==0){
                    this.$toast({message:'登录成功'})
                    // this.$store.commit('setUserInfo',result.data.user)
                    // this.$store.commit('setAccessToken',result.data.token)
                    // let path=this.$route.query.referrer
                    // this.$router.replace(path?path:'/index')



                    this.user=result.data.user
                    this.token=result.data.token
                    this.progress=2
                }else {
                    this.$toast({message:result.message})
                }
            },

            async loginClick(){
                if(!this.formData.name){
                    this.$toast({message:'请输入姓名'})
                    return false;
                }
                if(!this.formData.workNumber){
                    this.$toast({message:'请输入工号'})
                    return false;
                }
                this.buttonLoadtwo=true
                let result=await this.$request({
                    method: 'post',
                    url:'/app/auth/info',
                  headers:{
                    token:this.token
                  },
                    data:{
                       // phone:this.formData.phone,
                       name:this.formData.name,
                       workNumber:this.formData.workNumber
                    }
                })
                console.log(result);
                this.buttonLoadtwo=false
                if(result.code==0){
                    this.$toast({message:'验证成功'})
                    // this.$store.commit('setUserInfo',{
                    //     phone:this.formData.phone,
                    //     name:this.formData.name,
                    //     token:result.data
                    // })

                  this.$store.commit('setUserInfo',result.data)
                  this.$store.commit('setAccessToken',this.token)



                    let path=this.$route.query.referrer
                    this.$router.replace(path?path:'/index')
                }else {
                    this.$toast({message:result.message})
                }
            },
            setTime(time) {
                let that = this
                that.time = time
                clearInterval(that.int)
                if (time == 60) {
                    localStorage.setItem('setCodeTime', parseInt(new Date().getTime() / 1000))
                }
                that.int = setInterval(function () {
                    that.time--
                    if (that.time <= 0) {
                        clearInterval(that.int)
                    }
                }, 1000)
            },

        }
    }
</script>
<style lang="scss" scoped>

    .wrap{
        position: relative;
        width: 100%;
        min-height: 100vh;
        overflow: hidden;
        box-sizing:border-box;
        padding-top: 30px;
        padding-bottom: 150px;
        .content-box{
            position: relative;
            width: 688px;
            height: 899px;
            box-sizing:border-box;
            padding-top: 85px;
            /*position: absolute;*/
            /*left: 0;*/
            /*right: 0;*/
            /*top: 115px;*/
            background: #ffffff;
            margin: auto;
            border-radius:20px;
            padding-bottom: 40px;
        }
    }

    .logo{
        width: 134px;
        height: 134px;
        margin: 0 auto;
        background: url("https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-5.png");
        background-size: 100%;
    }
    .logo-label{
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        line-height: 56px;
        color: #C70C0C;
        margin-top: 20px;
    }
    .form{
        /*padding-top: 328px;*/
        width: 586px;
        margin: 0 auto;
        margin-top: 85px;
        .item{
            display: flex;
            align-items: center;
            height:108px;
            background:rgba(245, 245, 245, 1);
            border-radius:5px;
            padding-left: 20px;
            box-sizing: border-box;
            margin-bottom: 30px;
            .icon{
                width: 57px;
                height: 57px;
            }
            .input{
                font-size: 32px;
                color: #111111;
                margin-left: 10px;
                width: 460px;
            }
            .input::-webkit-input-placeholder { /* WebKit browsers */
                color: #999999;
            }

        }
        .item-code{
            .input{
                font-size: 30px;
                color: #111111;
                margin-left: 10px;
                width: 300px;
                padding: 20px 0;
            }
            .button{
                width: 160px;
                text-align: center;
                font-size: 26px;
                color: rgba(199, 12, 12, 1);
                div{
                    line-height:90px;
                }
            }
        }
    }


    .sub-button{
        padding-top: 50px;
        .van-button {
            font-size: 32px;
            height: 108px;
        }
    }

    .logo-two{
        width: 153px;
        height: 131px;
        margin: 0 auto;
        background: url("https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-4.png");
        background-size: 100%;
        margin-top: 40px;
    }


    .lin-box{
      width: 700px;
      margin: 0 auto;
      padding: 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        border-radius: 15px;
      }
      .left{
        font-size: 42px;
        color: #111111;
      }
    }

    .lin{
      font-size: 32px;
      height: 100px;
      width: 200px;
      margin: 0 auto;
      color: rgba(199, 12, 12, 1);
      border: 2PX solid rgba(199, 12, 12, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      //margin-top: 30px;
      font-weight: bold;
      border-radius: 100px;
    }
</style>
